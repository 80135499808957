<template>
  <step-lesson-layout :title="$t('module2.part3.lesson3.title')" :title-annotation="$t('module2.part3.lesson3.titleAnnotation')">
    <template v-slot:content>
      <div class="columns">
        <!-- CASE 1 -->
        <div class="column">
          <p class="title">{{ $t('module2.part3.lesson3.case1.title') }}</p>
          <step-list-item
            :label="$t('module2.part3.lesson3.stepLabel1')"
            :name="$t('module2.part3.lesson3.step1Name')"
            :steps="case1.step1"
          />
          <step-list-item
            :label="$t('module2.part3.lesson3.stepLabel2')"
            :name="$t('module2.part3.lesson3.step2Name')"
            :steps="case1.step2"
          />
          <step-list-item
            :label="$t('module2.part3.lesson3.stepLabel3')"
            :name="$t('module2.part3.lesson3.step3Name')"
            :steps="case1.step3"
          />
        </div>

        <!-- CASE 2 -->
        <div class="column">
          <p class="title">{{ $t('module2.part3.lesson3.case2.title') }}</p>
          <step-list-item
            :label="$t('module2.part3.lesson3.stepLabel1')"
            :name="$t('module2.part3.lesson3.step1Name')"
            :steps="[case2.step1]"
          />
          <step-list-item
            :label="$t('module2.part3.lesson3.stepLabel2')"
            :name="$t('module2.part3.lesson3.step2Name')"
            :steps="case2.step2"
          />
          <step-list-item
            :label="$t('module2.part3.lesson3.stepLabel3')"
            :name="$t('module2.part3.lesson3.step3Name')"
            :steps="case2.step3"
          />
        </div>
      </div>

      <!-- END BLOCK -->
      <div class="buttons-wrapper">
        <app-button-layout @click="$emit('next-tab')">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
      </div>
    </template>
  </step-lesson-layout>
</template>

<script>
import StepLessonLayout from '@/components/training/StepLessonLayout'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import useTrainingUtils from '@/utils/useTrainingUtils'
import StepListItem from '@/components/training/module2/part3/StepListItem.vue'

export default {
  name: 'Module2Part3Lesson3',
  components: { StepListItem, StepLessonLayout, AppButtonLayout },
  setup () {
    const { resumeLater, goToNextStep } = useTrainingUtils()
    return { resumeLater, goToNextStep }
  },
  data () {
    return {
      case1: {
        step1: [
          {
            name: 'maitre-oeuvre',
            image: require('@/assets/module2/part3/illu-mo.svg'),
            content: this.$t('module2.part3.lesson3.case1.step1')
          }
        ],
        step2: [
          {
            name: 'cc',
            image: require('@/assets/module2/part3/illu-cc.svg'),
            content: this.$t('module2.part3.lesson3.case1.step2a')
          },
          {
            name: 'chara-rge',
            image: require('@/assets/module2/part3/chara-rge.svg'),
            content: this.$t('module2.part3.lesson3.case1.step2b')
          },
          {
            name: 'devis',
            image: require('@/assets/module2/part3/illu-devis.svg'),
            content: this.$t('module2.part3.lesson3.case1.step2c')
          },
          {
            name: 'travaux',
            image: require('@/assets/module2/part3/illu-travaux.svg'),
            content: this.$t('module2.part3.lesson3.case1.step2d')
          }
        ],
        step3: [
          {
            name: 'facture-ah',
            image: require('@/assets/module2/part3/illu-facture-ah.svg'),
            content: this.$t('module2.part3.lesson3.case1.step3a')
          },
          {
            name: 'dossier',
            image: require('@/assets/module2/part3/illu-dossier.svg'),
            content: this.$t('module2.part3.lesson3.case1.step3b')
          },
          {
            name: 'prime',
            image: require('@/assets/module2/part3/illu-prime.svg'),
            content: this.$t('module2.part3.lesson3.case1.step3c')
          }
        ]
      },
      case2: {
        step1: {
          name: 'chara-rge',
          image: require('@/assets/module2/part3/chara-rge.svg'),
          content: this.$t('module2.part3.lesson3.case2.step1')
        },
        step2: [
          {
            name: 'cc-devis',
            image: require('@/assets/module2/part3/illu-cc-devis.svg'),
            content: this.$t('module2.part3.lesson3.case2.step2a')
          },
          {
            name: 'travaux',
            image: require('@/assets/module2/part3/illu-travaux.svg'),
            content: this.$t('module2.part3.lesson3.case2.step2b')
          }
        ],
        step3: [
          {
            name: 'facture-ah',
            image: require('@/assets/module2/part3/illu-facture-ah.svg'),
            content: this.$t('module2.part3.lesson3.case2.step3a')
          },
          {
            name: 'dossier',
            image: require('@/assets/module2/part3/illu-dossier.svg'),
            content: this.$t('module2.part3.lesson3.case2.step3b')
          },
          {
            name: 'prime',
            image: require('@/assets/module2/part3/illu-prime.svg'),
            content: this.$t('module2.part3.lesson3.case2.step3c')
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .title {
    margin-bottom: $space-s;
    font-size: 1.370rem; // to avoid text to be wrap to next line
    font-weight: $fw-l;
    color: $c-secondary;
    text-transform: uppercase;
  }

  .columns {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
    gap: $space-s;
    .column {
      background-color: #fff;
      border-radius: $radius;
      padding: $space-m;
    }
  }
  @media (max-width: $bp-tablet) {
    .columns {
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, 1fr);
    }
  }
</style>
