<template>
  <div class="step-list-item">
    <p class="label">{{ label }}</p>
    <p class="name">{{ name }}</p>
    <div class="step" v-for="(step) in steps" :key="'case1-step2-' + step.name">
      <div class="image-wrapper">
        <img :src="step.image" :alt="step.name">
      </div>
      <div class="content">
        <p class="paragraphe" v-html="step.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StepListItem',
  props: {
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    steps: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.step-list-item {
  padding-bottom: $space-m ;
  border-bottom: 2px solid $c-secondary;
  margin-bottom: $space-s ;

  .label {
    width: fit-content;
    padding: $space-xs $space-s;
    font-size: $fz-s;
    font-weight: $fw-l;
    border-radius: 50px ;
    color: white;
    background-color: $c-secondary;
    margin-bottom: $space-xs
  }

  .name {
    font-size: $fz-l;
    font-weight: $fw-l;
    margin-bottom: $space-m;
    font-family: $ff-big;
  }
  .step {
    display: flex;
    align-items: center;
    margin-bottom: $space-xs;
    .image-wrapper {
      min-width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: $space-m;
      img {
        max-height: 80px;
        width: 100%;
      }
    }
  }
  .step + .step {
    padding: $space-m 0;
    border-top: 1px solid $c-border;
  }
}
.step-list-item:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
</style>
